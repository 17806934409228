<template>
<div v-show="loading" style="background-color: #ffffff">
    <div class="position-fixed top-0 bottom-0 start-0 end-0 ol-4" style="z-index: 10000">
        <div class="d-flex justify-content-center align-items-center h-100">
            <img :src="require('../assets/loading.gif')" alt="page loading indication" class="rounded-circle" style="width: 100px; height: 100px;">
        </div>
    </div>
    <!-- <div class="position-fixed top-0 bottom-0 start-0 end-0" style="z-index: 10000">
        <div class="d-flex justify-content-center align-items-center h-100">
            <p class="mb-0 fs-4 brand-p-color font-brand"><i class="bi bi-hourglass-split"></i></p>
        </div>
    </div> -->
</div>
</template>

<script>
export default {
  name: 'PageLoading',
  computed: {
    loading () {
      return this.$store.getters['data/getLoadingState']
    }
  }
}
</script>
