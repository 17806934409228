<template>

  <VideoCover :file="coverFile" />

  <p class="mx-auto display-6 ls-1 mt-4 p-3 border-bottom w-fc">Our Products</p>
  <div class="container mx-auto pt-3 row g-4">

    <div class="card-group col-sm-6 col-lg-4" v-for="(product, index) in products" :key="index">
      <div class="card border-0 h-100">
        <router-link :to="'products/' + product.slug">
          <img :src="product.image" class="card-img-top of-contain" :alt="product.name" style="height: 225px">
        </router-link>
        <div class="card-body h-100 d-flex flex-column">
          <router-link :to="'products/' + product.slug" class="flex-fill text-decoration-none">
            <h5 class="card-title text-center text-capitalize" v-text="product.name"></h5>
          </router-link>
          <div class="btn-group w-100" role="group" aria-label="Basic example">
            <a :href="'https://wa.me/+919711992075?text=Hello,%20Green%20Grapes%20Diagnostics%20,I%20want%20to%20BUY%20' + product.name"
              target="_blank" class="btn btn-light brand-a-bg brand-s-color">Buy Now</a>
            <a :href="'https://wa.me/+919711992075?text=Hello,%20Green%20Grapes%20Diagnostics%20,I%20want%20to%20know%20more%20about%20' + product.name"
              target="_blank" class="btn btn-light brand-p-bg brand-s-color">Get Quote</a>
          </div>
        </div>
      </div>
    </div>

    <router-link to="products" class="text-center fw-bold ls-2">
      View More Products
    </router-link>

  </div>

  <MultiImageSlider title="Business Partners" :images="partners" />

  <SectionProcess />

  <SectionStatistics />

  <SectionAbout />

  <SectionJumbotron />

  <SectionTestimonials />

  <FloatingActionButton />

</template>

<script>
  import VideoCover from '../components/VideoCover.vue'
  import SectionProcess from '../components/SectionProcess.vue'
  import SectionAbout from '../components/SectionAbout.vue'
  import SectionStatistics from '../components/SectionStatistics.vue'
  import SectionTestimonials from '../components/SectionTestimonials.vue'
  import SectionJumbotron from '../components/SectionJumbotron.vue'

  import MultiImageSlider from '../components/MultiImageSlider.vue'
  import FloatingActionButton from '../components/FloatingActionButton.vue'

  export default {
    name: 'HomePage',
    components: {
      VideoCover,
      SectionProcess,
      SectionAbout,
      SectionStatistics,
      SectionTestimonials,
      SectionJumbotron,
      MultiImageSlider,
      FloatingActionButton
    },
    computed: {
      coverFile() {
        return this.$store.getters['repo/getCover']
      },
      partners() {
        return this.$store.getters['repo/getPartners']
      },
      products() {
        return this.$store.getters['repo/getInhouse'];
      }
    },

  }
</script>