import store from '@/store'
import router from '@/router'
import project from '@/service/project'

// Initialising axios
const Axios = require('axios').default

// Setting base url for accessing server
Axios.defaults.baseURL = project.getServerUrl() + project.getApiVersion()

// CORS and other headers
Axios.defaults.withCredentials = true
Axios.defaults.headers.post.Accept = 'application/json'
Axios.defaults.headers.post.ContentType = 'application/json'

// For making request to authenticated routes of server
if (localStorage.getItem('access_token') !== null) {
  Axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`
}

// Debugging network calls
if (project.inDev()) {
  // Debugging Request
  // Axios.interceptors.request.use(request => {
  //   store.dispatch('clearErrorsBag')
  //   console.log('Starting Request', JSON.stringify(request, null, 2))
  //   return request
  // })

  // Debugging Response
  // Axios.interceptors.response.use(response => {
  //   console.log('Response:', JSON.stringify(response, null, 2))
  //   return response
  // })
}

// Handling errors
Axios.interceptors.response.use(
  response => {
    store.dispatch('setErrorsBag', {})
    return response
  },
  error => {
    const ebag = {
      status: error.response.status,
      statusText: error.response.statusText,
      errors: error.response.data.errors
    }
    if (ebag.status === 422) {
      store.dispatch('setErrorsBag', ebag).then(() => {
        store.dispatch('endLoading')
      })
    } else if (ebag.status === 401) {
      store.dispatch('setErrorsBag', ebag).then(() => {
        store.dispatch('auth/clearAuthData').then(() => {
          router.replace({ name: 'Login' })
        })
      })
    } else if (ebag.status === 403) {
      store.dispatch('endLoading')
    } else if (ebag.status === 500) {
      store.dispatch('setErrorsBag', ebag).then(() => {
        store.dispatch('endLoading')
      })
    }
    // This will stop the response to go further this point
    return Promise.reject(error)
  }
)

export default Axios
