import Cache from './cache'

const api = {
  posts: {
    method: 'get',
    url: '/posts'
  },
  getPost (model) {
    return {
      method: 'get',
      url: '/posts/' + model
    }
  },
  getPath (key, model) {
    switch (key) {
      case Cache.posts: return this.posts
      case Cache.post: return this.getPost(model)
      default: return model
    }
  }
}

export default api
