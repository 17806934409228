<template>
    <footer id="appFooter">
        <div class="brand-p-bg brand-a-color">
            <div class="container-xl text-center text-lg-start">

                <!-- Social media -->
                <section class="d-block d-lg-flex align-text-center justify-content-center justify-content-lg-between p-4 border-bottom border-secondary">

                    <div class="fs-4 mb-lg-0 mb-4 text-center my-auto text-white">
                        Follow to get latest trends
                    </div>

                    <div>
                        <a v-for="(link, index) in footer.social" :key="index" :href="link.url"
                            class="me-4 text-reset td-none" target="__blank" :aria-label="link.title">
                            <img v-if="link.image" :src="link.image" alt="" class="wh-50 rounded">
                            <i v-else class="fs-4 bi " :class="link.icon"></i>
                        </a>
                    </div>

                </section>

                <div class="row text-center text-md-start mt-4">

                    <!-- Brand Details -->
                    <div class="col-lg-4 mx-auto mb-4">
                        <p class="text-uppercase font-brand text-warning ts-1 fw-bold display-6 mb-0 text-center text-lg-start" v-html="footer.brand"></p>
                        <p class="fs-4 fst-italic mb-0 text-center text-lg-start" v-html="brand.legal"></p>
                        <p class="fs-6 mb-0 text-center text-lg-start text-white" v-html="brand.about"></p>
                    </div>

                    <!-- Main Products -->
                    <div class="col-lg-3 border-1-top border-lg-none border-secondary pt-4">
                        <div class="text-lg-start btn fs-5 py-1 text-white fw-bolder">
                            <span class="d-inline-block d-lg-block d-xl-inline-block">Get In Touch</span>
                        </div>
                        <div class="d-flex flex-column text-center text-white">
                            <a v-for="(link, index) in footer.git" :key="index" :href="link.action" target="_blank"
                                class="text-lg-start btn fs-5 py-1" style="color: inherit;">
                                <i class="bi pe-2 pe-lg-2 fs-4" :class="link.icon"></i>
                                <span class="d-inline-block d-lg-block d-xl-inline-block">{{ link.title}}</span>
                            </a>
                        </div>
                    </div>

                    <!-- Google Map -->
                    <div class="col-lg-4 mx-auto my-4">
                        <iframe :src="footer.map" width="100%" height="212" style="border:0;"
                        allowfullscreen="true" name="TZP Magazine office, google location map"
                        loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                </div>

            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    footer () {
      return this.$store.getters['repo/getFooter']
    },
    brand () {
      return this.$store.getters['repo/getBrand']
    }
  }
}
</script>

<style lang="scss" scoped>
    #appFooter {
        background-image: url(../assets/bg-dot.jpg);
    }
</style>
