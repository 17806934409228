<template>
  <video class="of-contain w-100" playsinline autoplay muted loop>
    <source :src="file" type="video/mp4">
  </video>
</template>

<script>
export default {
  name: 'VideoCover',
  props: ['file']
}
</script>
