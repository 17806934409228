<template>
    <div class="bg-white row py-5 px-2">
        <div class="col-lg-5 order-2 order-lg-1">
            <img :src="about.image" :alt="about.name" class="w-100 of-contain" style="max-height: 450px">
        </div>
        <div class="col-lg-7 text-start order-1 order-lg-2">
            <p class="fs-2 brand-border-accent ts-sm fw-bolder border-25-start ps-4 font-title">About Us</p>
            <p class="lead overflow-hidden flex-fill" v-text="about.medium"></p>
            <router-link class="btn btn-link btn-outline-secondary text-dark" to="/about">Read More</router-link>
        </div>
    </div>
    <SectionStatements :statements="about.statements" :icons="false" />
</template>

<script>
import SectionStatements from './SectionStatements.vue'

export default {
  name: 'SectionAbout',
  components: {
    SectionStatements
  },
  computed: {
    about () {
      return this.$store.getters['repo/getAbout']
    }
  }
}
</script>

<style lang="scss" scoped>

    .grid-container {
        display: grid;
    }

    .grid-item-1 {
        grid-column: 1;
        grid-row: 1;
        margin: 0.52083vw;
    }

    .grid-item-2 {
        grid-column: 2;
        grid-row: 1 / span 3;
        margin: 0.52083vw;
    }

    .grid-item-3 {
        grid-column: 1;
        grid-row: 2 / span 3;
        margin: 0.52083vw;
    }

    .grid-item-4 {
        grid-column: 3;
        grid-row: 1;
        margin: 0.52083vw;
    }

    .grid-item-5 {
        grid-column: 3;
        grid-row: 2;
        margin: 0.52083vw;
    }

    .grid-item-6 {
        grid-column: 3;
        grid-row: 3;
        margin: 0.52083vw;
    }

</style>
