import { createStore } from 'vuex'
import forms from './modules/forms'
import repo from './modules/repo'
import event from './modules/event'
import blogy from './modules/blogy'

export default createStore({
  state: {
    ebag: {},
    loading: false
  },
  mutations: {
    setErrors (state, ebag) {
      state.ebag = ebag
    },
    setLoading (state, loading) {
      state.loading = loading
    }
  },
  actions: {
    setErrorsBag ({ commit }, ebag) {
      commit('setErrors', ebag)
    },
    clearErrorsBag ({ commit }) {
      commit('setErrors', {})
    },
    startLoading ({ commit }) {
      commit('setLoading', true)
    },
    endLoading ({ commit }) {
      commit('setLoading', false)
    }
  },
  getters: {
    errorsBag: state => state.ebag,
    loading: state => state.loading
  },
  modules: {
    repo: repo,
    event: event,
    blogy: blogy,
    forms: forms
  }
})
