const storage = {

  saveData (model) {
    // Create new object
    const jsonDataObject = {}

    // Convert data
    if (typeof(model.value) === 'object') {
      Object.entries(model.value).forEach((value) => {
        jsonDataObject[value[0]] = value[1]
      })
    } else {
      model.value.forEach((value, index) => {
        jsonDataObject[index] = value
      })
    }
    
    // Create array & Ammend data
    const data = []
    data.push(jsonDataObject)

    // Save to local storage
    window.localStorage.setItem(model.key, JSON.stringify(data))
  },
  hasData (key) {
    return window.localStorage.getItem(key) ? window.localStorage.getItem(key) : false
  },
  getData (key) {
    if (window.localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key))[0]
    } else {
      return null
    }
  }

}

export default storage
