<template>
    <div class="d-flex flex-wrap justify-content-around text-center pt-5">
        <div class="card border-0 shadow w-400 mb-5" v-for="(statement, index) in statements" :key="index">
            <div class="card-body text-start">
                <div v-if="icons" class="my-3 text-center">
                  <i class="bi display-1 brand-a-color op-8" :class="statement.icon"></i>
                </div>
                <p class="display-5 text-center ls-1 fw-bold text-uppercase font-title brand-a-color op-8"
                    v-text="statement.name"></p>
                <p v-if="icons" class="lead overflow-hidden" v-text="statement.text"></p>
                <div v-else>
                    <p class="lead overflow-hidden" v-text="statement.text" style="line-height: 1.8rem; max-height: 3.6rem;"></p>
                    <router-link class="btn btn-link btn-sm btn-outline-secondary text-dark float-end" to="/about">Read More
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'SectionStatements',
  props: ['statements', 'icons']
}

</script>
