<template>
  <div class="container-reverse">
    <swiper
      :centeredSlides="true"
      :autoplay="{
        delay: 10000,
        disableOnInteraction: false,
      }"
      :effect="'cube'"
      :grabCursor="true"
      :cubeEffect="{
        shadow: true,
        slideShadows: true,
        shadowOffset: 20,
        shadowScale: 0.94,
      }"
      :modules="modules"
      class="mySwiper"
    >
      <swiper-slide v-for="(punchline, index) in punchlines" :key="index">
        <div class="text-center px-3 py-5" :class="punchline.styles">
          <SectionTitle :title="punchline.title" />
          <div class="container ol-6 px-3 px-lg-5 py-5 br-all-20">
            <p class="fs-4 lh-base" v-text="punchline.text"></p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, EffectCube } from 'swiper'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-cube'
import SectionTitle from '../includes/SectionTitle.vue'

export default {
  name: 'SectionJumbotron',
  components: {
    Swiper,
    SwiperSlide,
    SectionTitle
  },
  data () {
    return {
      modules: [Autoplay, EffectCube]
    }
  },
  computed: {
    punchlines () {
      return this.$store.getters['repo/getPunchlines']
    }
  }
}
</script>
