<template>
    <p
      class="mx-auto fs-4 ls-1 mb-4"
      :class="cls"
      v-text="title">
    </p>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: ['title', 'cls']
}
</script>
