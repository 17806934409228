<template>
<div class="container-fluid py-5 bg-white">

  <p class="mx-auto display-6 ls-1 p-3 border-bottom w-fc" v-text="title"></p>

  <swiper
    :slidesPerView="imageCount"
    :spaceBetween="30"
    :slidesPerGroup="1"
    :loop="true"
    :loopFillGroupWithBlank="true"
    :autoplay="{
      delay: 2000,
      disableOnInteraction: false,
    }"
    :modules="modules"
    class="mySwiper"
  >

    <swiper-slide v-for="(image, index) in images" :key="index">
        <img :src="image" alt="multi image slider" class="of-contain w-100 h-100">
    </swiper-slide>

  </swiper>

</div>
</template>

<script>
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css/pagination'
import 'swiper/css/navigation'

SwiperCore.use([Autoplay])

export default {
  name: 'MultiImageSlider',
  props: ['title', 'images'],
   components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      modules: [Autoplay]
    }
  },
  computed: {
    brands () {
      return this.$store.getters['repo/getBrands']
    },
    imageCount () {
      let count = window.innerWidth / 300
      if ((window.innerWidth / 2)< 200) {
        count = 2
      }
      return count 
    }
  }
}
</script>
