const event = {
  namespaced: true,
  state: {
    activeProduct: {
      name: 'Screening Test For Typhoid IgG/IgM Ab',
      slug: 'screening-test-for-typhoid-igg-igm-ab',
      info: 'The Typhoid IgG/IgM is a rapid, qualitative, and differential test for the detection of anti satrnonella typhi (S.typhi) IgG and IgM in human serum and plasma. Detect anti-Salmonella typhi (S. typhi) IgG/IgM Specimen: Serum, Plasma.',
      image: require('../../assets/inhouse/screening-test-for-typhoid--iggIgm-ab.png'),
      feature: {
        0: 'Test Result: 5- 15 minutes',
        1: 'Shelf life: 2-400C for 24 months'
      }
    }
  },
  mutations: {
    updateActiveProduct (state, data) {
      state.activeProduct = data
    }
  },
  actions: {
    changeActiveProduct ({ commit }, data) {
      commit('updateActiveProduct', data)
    }
  },
  getters: {
    getActiveProduct(state, product_name) {
      console.log(product_name)
      return state.activeProduct
    }
  }
}
export default event
