<template>
    <nav id="appNavbar" class="navbar navbar-expand-lg py-0 fixed-top shadow bg-white">
        <div class="container">
            <router-link class="navbar-brand d-flex align-items-center" to="/">
                <img :src="brand.logo" :alt="brand.name" style="width: 60px">
                <div class="ms-3 d-flex flex-column lh-1">
                  <span class="fw-bold brand-p-color pb-1 fs-4">{{ brand.name1 }}</span>
                  <span class="ls-4 brand-a-color fs-5">{{ brand.name2 }}</span>
                </div>
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item" v-for="(link, index) in brand.links" :key="index">
                        <router-link class="nav-link text-dark" :to="link.path">{{ link.name }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>

export default {
  name: 'AppNavbar',
  computed: {
    brand () {
      return this.$store.getters['repo/getBrand']
    }
  },
  mounted () {
    const navLinks = document.querySelectorAll('.nav-link')
    const navbar = document.querySelector('.navbar-collapse')
    navLinks.forEach(link => {
      link.addEventListener('click', function (e) {
        navbar.classList.remove('show')
      })
    })
  }
}
</script>

<style lang="scss" scoped>
  //
</style>
