<template>

<div class="dropdown fab-btn bottom-0 end-0 shadow m-3">
  <button class="btn btn-warning" type="button" id="floatingActionButton" data-bs-toggle="dropdown" aria-expanded="false">
    <i class="bi bi-headset fs-2"></i>
  </button>
  <ul class="dropdown-menu bg-none text-end border-0 p-1" aria-labelledby="floatingActionButton">
    <li class="mb-2">
        <a :href="fab.whatsapp.link" target="_blank" class="btn btn-success w-fc">
            <i class="bi fs-3" :class="fab.whatsapp.icon"></i>
        </a>
    </li>
    <li class="mb-2">
        <a :href="fab.phone.link" target="_blank" class="btn btn-primary w-fc">
            <i class="bi fs-3" :class="fab.phone.icon"></i>
        </a>
    </li>
  </ul>
</div>

</template>

<script>
export default {
  name: 'FloatingActionbutton',
  computed: {
    fab () {
      return this.$store.getters['repo/getFab']
    }
  }
}
</script>

<style>
    .fab-btn {
        position: fixed;
        z-index: 100;
        bottom: 0px;
    }
    @media (min-width: 768px) {
        .fab-btn {
            bottom: 100px;
        }
    }
</style>
