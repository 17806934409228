<template>

<div class="bg-white">
  <div class="container text-center py-5">

    <SectionTitle title="Testimonials" cls="text-center brand-a-color" />

    <swiper
        :spaceBetween="30"
        :centeredSlides="true"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        :pagination="{
          clickable: true,
        }"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide v-for="(testimonial, index) in testimonials" :key="index">
            <div class="container px-4 mb-5">
                <img :src="testimonial.image" class="of-cover shadow wh-150 rounded-circle" :alt="testimonial.name">
                <p class="pt-3 lead" v-text="testimonial.message"></p>
                <p class="display-6 mb-2" v-text="testimonial.name"></p>
                <p class="text-uppercase ls-1 mb-0" v-text="testimonial.place"></p>
            </div>
        </swiper-slide>
    </swiper>

  </div>
</div>

</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination } from 'swiper'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import SectionTitle from '../includes/SectionTitle.vue'

export default {
  name: 'SectionTestimonials',
  components: {
    Swiper,
    SwiperSlide,
    SectionTitle
  },
  data () {
    return {
      modules: [Autoplay, Pagination]
    }
  },
  computed: {
    testimonials () {
      return this.$store.getters['repo/getTestimonials']
    }
  }
}
</script>
