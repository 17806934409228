<template>
    <div class="position-fixed zi-2k top-0 start-0 end-0 bottom-0" style="background-color: #00000080"
        v-if="showSuccessMessage">
        <div class="modal fade show" id="exampleModalLive" tabindex="-1" aria-labelledby="exampleModalLiveLabel"
            aria-modal="true" role="dialog" style="display: block;">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Form Submitted Successfully</h5>
                        <button type="button" class="btn-close" aria-label="Close" @click="showSuccessMessage = false"></button>
                    </div>
                    <div class="modal-body text-center">
                        <i class="bi bi-hand-thumbs-up fs-1 text-success d-block mb-3"></i>
                        <span class="lead">Thank you for submitting your form, we will correspond accordingly.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FormSubmittedMessage',
        data() {
            return {
                showSuccessMessage: false
            }
        },
        mounted() {
            const path = this.$router.currentRoute
            const form_status = path.value.query.form
            if (form_status === 'success') {
                this.showSuccessMessage = true
            }
        },
    }
</script>

<style>

</style>