const project = {

  stage: 'development',

  inDev () {
    return this.stage === 'development'
  },
  inProd () {
    return this.stage === 'production'
  },

  getServerUrl () {
    switch (this.stage) {
      case 'development': return 'http://localhost:8000'
      case 'production': return 'https://blogy.covisor.in'
    }
  },

  getApiVersion () {
    switch (this.stage) {
      case 'development': return '/api/v1/'
      case 'production': return '/api/v1/'
    }
  }
}

export default project
