import Api from '../../service/api'
import Logs from '../../service/logs'
import Server from '../../service/axios'
import Storage from '../../service/storage'
import Cache from '../../service/cache'

const blogy = {

  namespaced: true,

  state: {
      posts: {},
      post: {}
  },

  mutations: {
    updatePosts (state, modelData) {
        state.posts = { ...modelData }
        if (Logs.enabled) { Logs.step('posts data mutated') }
    },
    updatePost (state, modelData) {
        state.post = { ...modelData }
        if (Logs.enabled) { Logs.step('post data mutated') }
    },
    unloadPost () {
        state.post = {}
        if (Logs.enabled) { Logs.step('post data unmounted') }
    }
  },

  actions: {
    loadData ({ dispatch }, model) {
      if (Logs.enabled) { Logs.step('loading start') }
      // Data can be retrived only after
      // a. It must be a collection
      // b. Had been stored previously
      if (Cache.enabled) {
        const isCollection = model.value === null
        const hasLocalData = Storage.hasData(model.key)
        if (isCollection && hasLocalData) {
          // Get the data from local storage
          model.value = Storage.getData(model.key)
          if (Logs.enabled) { Logs.step('data retrived') }
          // EXIT: Pass it to update vuex store
          dispatch('updateData', model)
        } else {
          // Else simply fetch new data
          dispatch('fetchData', model)
        }
      } else {
        // Else simply fetch new data
        dispatch('fetchData', model)
      }
    },
    async fetchData ({ dispatch }, model) {
      await Server(Api.getPath(model.key, model.value))
        .then(response => {
          if (Logs.enabled) { Logs.step('data fetched') }
          const isCollection = model.value === null
          model.value = response.data.data
          if (isCollection) {
            Storage.saveData(model)
          }
          dispatch('updateData', model)
        })
        .catch((error) => {
            if (Logs.enabled) { 
                Logs.step('data not fetched')
                Logs.err(error)
            }
        })
    },
    updateData ({ commit }, model) {
      if (Logs.enabled) { Logs.step('data committing') }
      commit(Cache.getMutator(model.key), model.value)
    }
  },
  
  getters: {
    getPosts (state) {
        return state.posts
    },
    getPost(state) {
      return state.post
    }
  }
}

export default blogy
