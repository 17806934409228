import Repo from '@/store/modules/repo'

const cache = {

  enabled: true,

  posts: Repo.state.brand.name + '-posts',
  post: Repo.state.brand.name + '-post',

  getMutator (key) {
    switch (key) {
      case this.posts: return 'updatePosts'
      case this.post: return 'updatePost'
    }
  },

  isObject (key) {
    switch (key) {
      case this.posts: return false
      case this.post: return true
    }
  },

  isCollection (key) {
    switch (key) {
      case this.posts: return true
      case this.post: return false
    }
  }

}

export default cache
