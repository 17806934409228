<template>

<div class="container text-center pt-5">

    <SectionTitle title="Our Fun Facts" cls="text-center brand-a-color" />

    <div class="row pt-4 justify-content-between align-items-center">
        <div class="col-md-3 card border-success mb-5" v-for="(data, index) in statistics" :key="index">
            <div class="card-body d-flex flex-column justify-content-between rounded-circle m-2 m-lg-4">
                <p class="fs-2 ls-2 mb-2 fw-bold text-capitalize" v-html="data.number"></p>
                <p class="fs-5 mb-0 text-uppercase font-title" v-text="data.title"></p>
            </div>
        </div>
    </div>

</div>

</template>

<script>
import SectionTitle from '../includes/SectionTitle.vue'
export default {
  name: 'SectionStatistics',
  computed: {
    statistics () {
      return this.$store.getters['repo/getStatistics']
    }
  },
  components: { SectionTitle }
}
</script>
