<template>
  <div class="d-flex flex-column min-vh-100" style="max-width: 100vw; overflow-x: hidden;">

    <AppNavbar/>
    <PageLoading/>

    <div style="margin-top: 70px" class="flex-fill">
        <router-view></router-view>
    </div>

    <FormSubmittedMessage />
    <AppFooter/>
    <AppCopyright/>

  </div>
</template>

<script>
import AppCopyright from './components/AppCopyright.vue'
import PageLoading from './components/PageLoading.vue'

// Application level css & js
import './template/sass/index.scss'
import './template/js/index.js'

// Components html, css & js
import AppNavbar from './components/AppNavbar.vue'
import AppFooter from './components/AppFooter.vue'
import FormSubmittedMessage from './components/FormSubmittedMessage.vue'

export default {
  name: 'App',
  components: {
    AppNavbar,
    AppFooter,
    PageLoading,
    AppCopyright,
    FormSubmittedMessage
  }
}

</script>
