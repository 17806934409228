<template>

<div class="brand-p-bg">

    <div class="container py-5">

      <div class="row justify-content-between">

        <div class="col-lg-5">

          <SectionTitle :title="process.title" cls="brand-s-color"/>

          <div class="list-group">
              <div class="list-group-item mb-4 rounded bg-light p-0" v-for="(step, index) in process.steps" :key="index">
                  <div class="card-body d-flex text-start p-2">
                      <span v-text="index"></span>.
                      <span v-text="step" class="ms-2"></span>
                  </div>
              </div>
          </div>

        </div>

        <div class="col-lg-7 overflow-hidden">
          <img :src="process.image" alt="" class="of-contain w-100 op-bottom rounded" style="max-height: 450px">
        </div>

      </div>

    </div>

</div>

</template>

<script>
import SectionTitle from '../includes/SectionTitle.vue'
export default {
  name: 'SectionProcess',
  computed: {
    process () {
      return this.$store.getters['repo/getProcess']
    }
  },
  components: { SectionTitle }
}
</script>
